.mTable {
  width: auto !important;
}

.mTable>.ant-table-thead {
  .ant-table-cell {
    background: #f9fafb !important;
    color: theme('colors.charcoal') !important;
    font-size: 16px;
    line-height: 20px;
    border: none !important;
    border-bottom: 1px dashed #e6e6e6 !important;
    text-align: center !important;
  }
}

.mTable .ant-table-thead {
  .ant-table-cell {
    background: #f9fafb !important;
    color: theme('colors.charcoal') !important;
    ;
    font-size: 16px;
    line-height: 20px;
    border: none !important;
    border-bottom: 1px dashed #e6e6e6 !important;
    text-align: center !important;
  }
}

.mTable > .ant-table-tbody {
  .ant-table-cell {
    color: theme('colors.grey');
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    background: #f9fafb;
    border: none !important;
    border-left: 1px dashed #e6e6e6 !important;
    border-right: 1px dashed #e6e6e6 !important;
  }
}

/* .ant-table-cell:nth-child(1) {
  border-left: none !important;
}

.ant-table-cell:last-child {
  border-right: none !important;
} */

.ant-dropdown-menu-title-content {
  color: theme('colors.grey') !important;
}

.stock-details-table {
  .ant-table-tbody {
    .ant-table-cell:last-child {
      background-color: #f9f1f2 !important;
      color: theme('colors.error') !important;
      ;
    }

    .ant-table-cell:nth-child(3) {
      background: #edf6f4;
      color: theme('colors.green') !important;
    }
  }
}

.custom-input .ant-select-selector {
  height: 3rem !important;
  display: flex;
  align-items: center;
  background: #f9fafb !important;
  border: 1px solid #e6e6e6;
}

/* Customized Button */

.ant-btn-primary {
  background-color: theme('colors.primary') !important;
}

.country-picker .ant-select-selector {
  background-color: #fff !important;
}

.searchField .ant-input {
  background-color: #e6e6e6;
}