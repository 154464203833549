.center-form {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.screen-bg {
  width: 100%;
  min-height: 100vh;
  background: url('/src/assets/images/loginBG.svg'), linear-gradient(to bottom, #70c4ff, #0C8CE9);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}