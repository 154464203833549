/* Empty State */
.empty-state {
  .content {
    gap: 0.5rem;
    margin-top: 10%;

    .link {
      color: #0c8ce9;
      font-size: 1rem;
      text-decoration: underline;
    }
  }

  @media (min-width: 1440px) {
    .content {
      gap: 1.5rem;
    }
  }
}

/* Import Customers */
.import-customer {
  .content {
    width: 100%;
    gap: 0.25rem;
  }

  .dropzone {
    width: 100%;
    max-width: 40rem;
    background: #efefef;
    border-radius: 1rem;
    border: 2px dashed #e6e6e6;
    gap: 1rem;
    padding: 1rem;
    margin-top: 5rem;
  }

  @media (min-width: 1440px) {
    .dropzone {
      margin-top: 10rem;
    }
  }

  .upload-icon {
    height: 3rem;
    width: 3rem;
    font-size: 3rem;
    background-color: #fff;
    padding: 10px;
    border-radius: 50%;
    color: gray;
  }
}

/* Cashbook Styles Start here */

.cashbook-header-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

/* Stock book style */
.stock-item-details-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 1rem;

  .heading {
    line-height: 20%;
  }

  .sub-heading {
    color: #98999b;
  }
}

/* Bill Book Styles */
/* .billbook-card {
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  margin: auto;
  border-radius: 8px;

  .heading {
    line-height: 70%;
  }

  .date {
    line-height: 70%;
    color: #98999b;
  }

  .cashIn-btn {
    margin-bottom: 1rem;
  }

  .description {
    line-height: 50%;
  }

  .details-btn {
    height: fit-content;
    padding: 0;
  }
} */

.input-file {
  display: none;
}

.input-file-div,
.items-select {
  width: 100%;
  height: 3rem;
  background: #f9fafb;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  color: #98999b;
  padding-left: 0.5rem;

  .placeholder {
    color: #bbbbbc;
    margin-top: 0.7rem;
  }
}

.items-select {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    color: #bbbbbc;
    margin-right: 0.5rem;
  }
}

.display-images {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  .photo {
    height: 50px;
    width: 50px;
    border-radius: 4px;
    object-fit: cover;
  }
}

.add-item-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  .row,
  .radio {
    width: 100%;
  }

  .radio {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .radio-button {
      gap: 1rem;
      .ant-radio {
        margin-top: -5.2rem;
      }
      .title,
      .desc {
        line-height: 50%;
      }

      .title {
        font-size: 1rem;
        font-weight: 500;
      }

      .desc {
        color: #bbbbbc;
      }
    }

    .quantity-rate {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

.horizontal-row {
  height: 0.5px;
  width: 100%;
  background: #e6e6e6;
  margin-top: 1rem;
}
