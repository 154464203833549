@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter-Light";
  src: url("../assets/fonts/Inter-Light.ttf");
}

@font-face {
  font-family: "Inter-Regular";
  src: url("../assets/fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url("../assets/fonts/Inter-SemiBold.ttf");
}

@font-face {
  font-family: "Inter-Bold";
  src: url("../assets/fonts/Inter-Bold.ttf");
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: 'Inter-Regular', sans-serif;
}


/* Scrollbar track */
::-webkit-scrollbar {
  width: 6px;
  height: 3px;
  border-radius: 6px;
  cursor: pointer;
}

/* Scrollbar thumb (handle) */
::-webkit-scrollbar-thumb {
  background-color: theme('colors.grey') !important;
  border-radius: 6px;
  cursor: pointer;
}


.page-padding {
  padding: 1rem 0.25rem;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.display-center-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Table Header with Search input and action buttons styles start */
.table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1rem;

  .search-input {
    height: 44px;
    max-width: 244px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    color: #98999b;
  }
}

.action-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  .icon {
    height: 44px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #98999b;
    border-radius: 0.5rem;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }
}

/* Table Header with Search input and action buttons styles end */

/* Table Styles */
.table-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #f9fafb;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  padding-bottom: 1rem;
}

.table {
  width: 100%;
  max-width: 100%;
  overflow: auto;
}

/* Table action dropdown button */
.action-btn {
  display: flex;
  align-items: center;
  gap: 2;
  background: #e6e6e6;
  color: #98999b;
  margin: auto;
}

.icon {
  height: 14px;
  width: 14px;
  color: #98999b;
}

/* Table Styles End */

/* Modal */
.modal {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding-top: 1rem;
}

/* Modal */

.custom-autocomplete {

  display: flex;
  flex-direction: column;
  height: 3rem !important;
  
  .ant-select-selector {
    display: flex !important;
    align-items: center !important;
    background: #f9fafb !important;
    height: 3rem !important;
  }

  .ant-select-selection-search-input {
    height: 3rem !important;
  }
}



.custom-input {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  .label {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
  }

  .input-field,
  .text-area,
  .select {
    background: #f9fafb;
    border: 1px solid #e6e6e6;
  }

  .input-field {
    height: 3rem;
  }
}

input[type="date"] {
  color: #98999b;
}

.fieldValidationError {
  color: red !important;
  margin-top: 4px;
}

.PhoneInputInput {
  min-width: 100px !important;
  /* max-width: 100%; */
  padding: 10px 8px 10px 8px;
  border-radius: 6px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-width: 1px;
  border-color: rgb(152 153 155 / var(--tw-border-opacity));

  @media screen and (max-width: 420px) {
    width: 100px !important;
  }

}

.PhoneInputCountry {
  padding-right: 10px !important;
}

.PhoneInputCountryIcon {
  margin-right: 10px !important;
}

.loading-spinner {
  z-index: 100;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #0C8CE9;
  -webkit-animation: PreloaderSpin 2s linear infinite;
  animation: PreloaderSpin 2s linear infinite;
  scale: 1.2;

  &:before {
    content: '';
    display: block;
    width: 60px;
    height: 60px;
    margin: 8px 0 0px 8px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: red;
    -webkit-animation: PreloaderSpin 6s linear infinite;
    animation: PreloaderSpin 6s linear infinite;
  }

  &:after {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    margin: -52px 0 0px 18px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: green;
    -webkit-animation: PreloaderSpin 3s linear infinite;
    animation: PreloaderSpin 3s linear infinite;
  }
}

@keyframes PreloaderSpin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}